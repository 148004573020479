
import React from "react";
import { Col, Tabs, Tab, Dropdown } from "react-bootstrap";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  TimeScale,
  Tooltip,
  Legend,
} from "chart.js";
import "chartjs-adapter-luxon";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  TimeScale,
  Title,
  Tooltip,
  Legend
);

const verticalLinePlugin = {
  id: "verticalLines",
  beforeDraw: function (chart, args, options) {
    const area = chart.chartArea;
    const context = chart.ctx;
    const xAxis = chart.scales.x;

    if (chart.config.options.vlines) {
      // render vertical line
      if (context !== null) {
        context.save();
        context.globalCompositeOperation = "destination-over";
        context.fillStyle = "#d6d6d634";
        context.fillRect(0, 0, chart.width, chart.height);
        context.restore();

        context.beginPath();
        context.lineWidth = 1;
        context.strokeStyle = "#d6d6d6";
        context.fillStyle = "#d9d9d934";
        context.moveTo(0, Math.floor(area.height + area.top));
        context.lineTo(
          area.width + area.right,
          Math.floor(area.height + area.top)
        );

        xAxis.ticks.forEach(function (value, index, array) {
          const tickDate = new Date(xAxis.ticks[index].value);
          const xTicks = xAxis.getPixelForValue(tickDate);
          console.log('XVP xTicks', xTicks);

          context.moveTo(xTicks, 0);
          context.lineTo(xTicks, area.height + area.top);
        });

        context.stroke();
      }
    }

  },
};

ChartJS.register(verticalLinePlugin);

const ChartSection = React.memo(({
  selectedPeriod,
  periodHandler,
  options,
  chartData,
}) => {
  return <>
    <Col md={12} className="d-block d-md-none">
      <Dropdown className="d-block d-md-none">
        <Dropdown.Toggle variant="outline-secondary" id="dropdown-basic">
          {selectedPeriod}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item onClick={() => periodHandler("week")}>
            {"1 Week"}
          </Dropdown.Item>
          <Dropdown.Item onClick={() => periodHandler("month")}>
            {"1 Month"}
          </Dropdown.Item>
          <Dropdown.Item onClick={() => periodHandler("quarter")}>
            {"1 Quarter"}
          </Dropdown.Item>
          <Dropdown.Item onClick={() => periodHandler("year")}>
            {"1 Year"}
          </Dropdown.Item>
          <Dropdown.Item onClick={() => periodHandler("ytd")}>
            {"YTD"}
          </Dropdown.Item>
          <Dropdown.Item onClick={() => periodHandler("all")}>
            {"All"}
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </Col>
    <Col md={12} className="d-md-block">

      <div className="tabs">
        <Tabs
          id="venues_tab_category"
          activeKey={selectedPeriod}
          onSelect={(k) => periodHandler(k)}
          className="d-none d-md-flex justify-content-end"
        >
          <Tab eventKey={"week"} title={"1 Week"}></Tab>
          <Tab eventKey={"month"} title={"1 Month"}></Tab>
          <Tab eventKey={"quarter"} title={"1 Quarter"}></Tab>
          <Tab eventKey={"year"} title={"1 Year"}></Tab>
          <Tab eventKey={"ytd"} title={"YTD"}></Tab>
          <Tab eventKey={"all"} title={"All"}></Tab>
        </Tabs>
        <div className="chart">
          <Line options={options} data={chartData} />
        </div>
      </div>

    </Col>
  </>
});

export default ChartSection;
