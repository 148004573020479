/* eslint-disable no-console, jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events, jsx-a11y/label-has-associated-control*/
import React, { useEffect, useState } from "react";
import { Col, Image } from "react-bootstrap";
import clsx from "clsx";
import { DateTime } from "luxon";
import { useDispatch, useSelector } from "react-redux";
import { navigate } from "gatsby";
import { Table, Thead, Tbody, Tr, Th, Td } from "../../Table";
import MiniChart from "../../MiniChart";
import {
  numberWithCommas,
  getStatus,
  dateDiffInDays,
  isMobile,
  isValidDateString,
  getCurrentPrice,
} from "../../../utils";
import {
  actionTypes,
} from "../../../store/Dialog/type";
import PaymentService from "../../../services/PaymentService";
import OpalFile from "../../InvestTrade/PageContent/OpalFile";
import {
  showAlert,
  NOT_VERIFIED_OPAL_BUY,
  NOT_VERIFIED_DEEP_OPAL_INFO,
  SUBSEQUENT_LOG_INS,
} from "../../../store/reducers/alert";

const paymentService = new PaymentService();

const TransactionSection = ({
  transactionId,
  account,
  selectedPeriod,
  slug,
  ria,
  completedTransaction,
  pendingTransaction,
  opals,
}) => {
  const dispatch = useDispatch();
  const [currentPrices, setCurrentPrices] = useState({});
  const [completedOpal, setCompletedOpal] = useState([]);

  const user = useSelector(
    (state) => state?.app?.profile
  );

  const accounts = useSelector(
    (state) => state?.accounts?.parentAccounts
  );

  const showWireInstructionHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch({ type: actionTypes.SHOW_DIALOG, value: { showSub: true, subType: 'dialog_wire_instruction' } })
  }

  const cancelPurchaseHandler = (e, transaction) => {
    e.preventDefault();
    e.stopPropagation();
    const opal = getOpal(transaction);

    dispatch({ type: actionTypes.SHOW_DIALOG, value: { showSub: true, subType: 'dialog_cancel_purchase', transaction, opal: opal } })
  }

  const mediaHandler = (url, label, opal_name) => {
    if (accounts.filter(el => el.hasOwnProperty('approvalTemplate')).length === 0) {
      const alertType = { type: NOT_VERIFIED_DEEP_OPAL_INFO };
      showAlert({ ...alertType, subType: accounts.length > 0 && accounts[0].type === 'ria' ? 'ria' : 'non-ria' });
    } else {
      if (url.split('.').pop() === 'pdf') {
        let new_window;

        new_window = window.open('', '_blank', 'toolbar=0,location=0,menubar=0,popup=1,titlebar=0,location=0');

        let html_contents = `<embed src="${url}" style="width: 100%; height: 100vh"/>`;

        new_window.document.write('<!doctype html><html><head><title></title><meta charset="UTF-8" /><meta name="viewport" content="width=device-width"></head><body style="background-color: rgb(0, 0, 0);margin:0px">' + html_contents + '</body></html>');

      } else {
        dispatch({ type: actionTypes.SHOW_DIALOG, value: { type: 'dialog_video', show: true, payment: null, data: {opal_name, label, url} } });
      }
    }
  }

  const signDocument = async (e, transaction) => {
    e.preventDefault();
    e.stopPropagation();

    const cost = transaction.cost;
    const units = transaction.units
    const unit_price = transaction.unit_price;
    const opal = getOpal(transaction);

    if (ria) {
      // resend document
      dispatch({ type: actionTypes.SHOW_DIALOG, value: { loading: true } });
      await paymentService.updatePaymentProcess({
        document_hash: transaction.document_hash,
        step: 'dialog_signature',
        resend: '1',
        title: `${user.firstName} ${user.lastName} has sent you documents to sign for ${transaction.opal_name} Opal${opal?.opal_series_suffix ? opal?.opal_series_suffix : ''} units purchase`,
        opal_suffix: opal?.opal_series_suffix ? opal?.opal_series_suffix : ''
      });

      const bankInfo = transaction.bank.split('*');
      dispatch({
        type: actionTypes.SHOW_DIALOG, value: {
          loading: false,
          show: true,
          type: 'dialog_preview',
          account,
          documentData: { document_hash: transaction.document_hash },
          cost: units * unit_price,
          paymentItem: {
            institution: {
              name: bankInfo[0]
            }
          },
          paymentAccounts: {
            accounts: [
              { mask: bankInfo.length === 2 ? bankInfo[1] : '' }
            ]
          },
          opal: {
            ria_fee: (cost - units * unit_price) / (units * unit_price) * 100,
            opal_name: transaction.opal_name,
            unit_price: transaction.unit_price,
            opal_image: {
              url: transaction.opal_url,
            },
            brand: {
              data: {
                brand_image_title: [
                  {
                    text: transaction.brand_name
                  }
                ]
              }
            }
          }
        }
      })
    } else {
      // sign document
      dispatch({
        type: actionTypes.SHOW_DIALOG, value: {
          show: true,
          loading: false,
          type: 'dialog_signature',
          documentData: {
            document_hash: transaction.document_hash
          },
          account,
          opal: {
            'non-ria_fee': (cost - units * unit_price) / (units * unit_price) * 100,
            opal_name: transaction.opal_name,
            unit_price: transaction.unit_price,
            opal_image: {
              url: transaction.opal_url,
            },
            brand: {
              data: {
                brand_image_title: [
                  {
                    text: transaction.brand_name
                  }
                ]
              }
            }
          }
        }
      })
    }
  }

  const getOpal = (t) => {
    const item = opals.find(el => el.opal.opal_name === t.opal_name && el.brand.uid === t.brand_uid);
    if (item) {
      return item.opal;
    } else {
      return t
    }
  }

  const getBrand = (t) => {
    const item = opals.find(el => el.opal.opal_name === t.opal_name && el.brand.uid === t.brand_uid);
    if (item) {
      return item.brand;
    } else {
      return t
    }
  }

  const getFundManager = (t) => {
    const item = opals.find(el => el.opal.opal_name === t.opal_name && el.brand.uid === t.brand_uid);
    if (item) {
      return item.brand.data.brand_image_title[0].text;
    } else {
      return ""
    }
  }

  const getCompletedOpal = (completedTransaction) => {
    const item = opals.find(el => el.opal.opal_name === completedTransaction.opal_name && el.brand.uid === completedTransaction.brand_uid);
    return item
  }

  const getPriceByTransaction = (t, transId) => {
    let key = `${t._id}${transId}`;
    return currentPrices.hasOwnProperty(key) ? currentPrices[key] : 0;
  }

  useEffect(() => {
    async function fetchCurrentPrice() {
      if (!(completedTransaction.length === 0 && transactionId)) {
        const curPrices = {};

        for (const transaction of completedTransaction) {
          let key = `${transaction._id}${transactionId}`;
          curPrices[key] = await getCurrentPrice(getOpal(transaction));
        };

        setCurrentPrices(curPrices);
        console.log('CCP_cp', curPrices);
      }
    }
    fetchCurrentPrice();
  }, [transactionId]);
  
  useEffect(() => {
    let completedOpalList = [];
    if (!(completedTransaction.length === 0 && transactionId)) {
      completedTransaction.map((item) => {
        completedOpalList.push(getCompletedOpal(item))
      })
      setCompletedOpal(completedOpalList);
    }
  }, [completedTransaction])

  const shareOpalHandler = (opal, brand) => {
    dispatch({ type: actionTypes.SET_OPAL, value: { ...opal, brand } });
    dispatch({ type: actionTypes.SHOW_DIALOG, value: { type: 'dialog_share_opal_preview', show: true, selectedAccounts: [account] } });
  }

  // const ria = accounts?.[0]?.type === 'ria'

  return (
    <>
      {!transactionId && <Col md={12} className="d-md-block">
        <div>
          <div className="text-left">
            <b>PORTFOLIO ASSETS</b>
          </div>
          <div className="cash-balance">
            <div className="label">
              <b>Cash balance <span className="linkable">(?)</span></b>
            </div>
            <div>
              ${numberWithCommas(account.cash || 0)}
            </div>
          </div>
        </div>
      </Col>}
      {!(completedTransaction.length === 0 && transactionId) && <Col md={12} className="d-md-block">
        <table className="responsiveTable" style={{"margiBottom": '10px'}}>
          <Thead>
            <Tr>
              <Th style={{ width: '30%' }}>Opals <span className="linkable">(?)</span></Th>
              <Th style={{ width: '24%' }}>Fund Manager</Th>
              <Th style={{ width: '15%' }}>Balance</Th>
              <Th style={{ width: '15%' }}>Price/unit</Th>
              <Th style={{ width: '16%' }}>Performance ({selectedPeriod})</Th>
            </Tr>
          </Thead>
        </table>
        {completedTransaction.map((transaction, index) => {
          const isWithBreakItem = index >= 1
          const completedOpal = getCompletedOpal(transaction)
          return (
            <>
              <Table className={`${isWithBreakItem ? 'break-line' : ''}`}>
                <Thead>
                  <Tr className="no-border">
                    <Th style={{ width: '30%' }}></Th>
                    <Th style={{ width: '24%' }}></Th>
                    <Th style={{ width: '15%' }}></Th>
                    <Th style={{ width: '15%' }}></Th>
                    <Th style={{ width: '16%' }}></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr
                    className={clsx("d-md-table",
                      {
                        "hover-table": transactionId ? false : true,

                      })}
                    key={`${transaction._id}${transactionId}`}
                    onClick={() => navigate(`/portfolio/${slug}/?brandUid=${transaction.brand_uid}&tid=${transaction._id}`)}

                  >
                    <Td className="opal-div">
                      <div className="invester-div">
                        <Image
                          className="edit-icon"
                          src={transaction.opal_url}
                        />
                        <div className="account-field">
                          <div className="name-label">
                            <b>{transaction.opal_name}</b>
                          </div>
                        </div>
                      </div>
                    </Td>

                    <Td>
                      <div className="assets-div">
                        <p style={{marginBottom: '10px'}}>
                          { getFundManager(transaction)}
                        </p>
                      </div>
                    </Td>

                    <Td>
                      <div className="assets-div">
                        <p style={{marginBottom: '10px'}}>
                          ${numberWithCommas(transaction.units * getPriceByTransaction(transaction, transactionId))}
                        </p>
                        <p style={{marginBottom: '10px'}}>
                          {numberWithCommas(transaction.units, false)} unit{transaction.units === 1 ? '' : 's'}
                        </p>
                      </div>

                    </Td>
                    <Td>
                      <div className="assets-div">
                        <p style={{marginBottom: '10px'}}>
                          ${numberWithCommas(getPriceByTransaction(transaction, transactionId))}
                        </p>
                      </div>

                    </Td>
                    <Td>
                      <MiniChart transaction={transaction} period={selectedPeriod} />
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
              {transactionId && completedOpal?.opal !== undefined && (
                <OpalFile 
                  opal={completedOpal.opal}
                  mediaHandler={mediaHandler}
                />
              )}
            </>
          )
        })}
        {(completedTransaction.length === 0) && <div className="no-opal">No Opals issued</div>}
      </Col>}
      {!(pendingTransaction.length === 0 && transactionId) && <Col md={12} className="d-md-block mt-50">
        <table id="pending-table" className="responsiveTable" style={{"margiBottom": '10px'}}>
          <Thead>
            <Tr>
              <Th style={{ width: '30%' }}>Pending <span className="linkable">(?)</span></Th>
              <Th style={{ width: '24%' }}>Fund Manager</Th>
              <Th style={{ width: '15%' }}>Balance</Th>
              <Th style={{ width: '15%' }}>Price/unit</Th>
              <Th style={{ width: '16%' }}>Performance ({selectedPeriod})</Th>
            </Tr>
          </Thead>
        </table>
            {pendingTransaction.map((transaction, index) => {
              const isWithBreakItem = index >= 1
              const pendingOpal = getCompletedOpal(transaction)
              return <>
              <Table className={`${isWithBreakItem ? 'break-line' : ''}`}>
                <Thead>
                  <Tr className="no-border">
                    <Th style={{ width: '30%' }}></Th>
                    <Th style={{ width: '24%' }}></Th>
                    <Th style={{ width: '15%' }}></Th>
                    <Th style={{ width: '15%' }}></Th>
                    <Th style={{ width: '16%' }}></Th>
                  </Tr>
                </Thead>
                <Tbody>
              
              <Tr
                key={`${transaction._id}${transactionId}`}
                className={clsx("d-md-table",
                  {
                    "hover-table": transactionId ? false : true,
                  })}
                onClick={() => navigate(`/portfolio/${slug}/?brandUid=${transaction.brand_uid}&tid=${transaction._id}`)}
              >
                <Td className="opal-div">
                  <div className="invester-div">
                    <Image
                      className="edit-icon"
                      src={transaction.opal_url}
                    />
                    <div className="account-field">
                      <div className="name-label">
                        <b>{transaction.opal_name}</b>
                      </div>
                    </div>
                  </div>
                </Td>

                <Td>
                  <div className="assets-div">
                    <p style={{marginBottom: '10px'}}>
                      { getFundManager(transaction)}
                    </p>
                  </div>
                </Td>

                <Td>
                  <div className="assets-div">
                    <div>
                      ${numberWithCommas(transaction.units * transaction.unit_price)}
                    </div>
                    <div className="d-block d-md-none">
                      {numberWithCommas(transaction.units, false)} unit{transaction.units === 1 ? '' : 's'}
                    </div>
                  </div>

                </Td>
                <Td>
                  <div className="assets-div">
                    <div>
                      ${numberWithCommas(transaction.unit_price)}
                    </div>
                  </div>

                </Td>
                <Td>
                  {getStatus(transaction, ria) === 'esign' && <div className="status-esign-div">
                    <div className="status">
                      <b>Awaiting e-sign</b> {(dateDiffInDays(new Date(transaction.end_date), new Date()) < 3) && <>(<i>expires in <b>3 days</b></i>)</>}
                      <div className="linkable" onClick={(e) => signDocument(e, transaction)}>
                        <b>{ria ? 'Resend documents' : 'Sign documents'}</b>
                      </div>
                    </div>
                    <div className="linkable ml-10 mt-20" onClick={(e) => cancelPurchaseHandler(e, transaction)}>
                      <b>Cancel purchase</b>
                    </div>
                  </div>}
                  {getStatus(transaction, ria) === 'awaiting' && <div className="status-issuance-div">
                    <div>
                      <b>Awaiting issuance on {isMobile() && <br />}{
                        DateTime.fromJSDate(isValidDateString(getOpal(transaction).issuance_date) ? 
                          new Date(getOpal(transaction).issuance_date) < new Date(getOpal(transaction).ends) ? 
                            DateTime.fromFormat(getOpal(transaction).ends, 'yyyy-MM-dd').plus({ days: 5 }).toJSDate() : 
                            DateTime.fromFormat(getOpal(transaction).issuance_date, 'yyyy-MM-dd').toJSDate() : 
                          DateTime.fromFormat(getOpal(transaction).ends, 'yyyy-MM-dd').plus({ days: 5 }).toJSDate()).toLocaleString(DateTime.DATE_SHORT)}</b>
                    </div>
                  </div>}
                  {getStatus(transaction, ria) === 'awaiting_wire' && <div className="status-wire-div">
                    <div>
                      Awaiting wire {(dateDiffInDays(new Date(getOpal(transaction).ends), new Date()) <= 1) && <>due <b>today</b></>}
                    </div>
                    <div className="linkable" onClick={showWireInstructionHandler}>
                      Transfer instructions
                    </div>
                  </div>}
                  {getStatus(transaction, ria) === 'awaiting_wire_transfer' && <div className="status-wire-transfer-div">
                    <div>
                      Awaiting wire transfer
                    </div>
                    <div className="linkable" onClick={showWireInstructionHandler}>
                      Wire instructions
                    </div>
                  </div>}

                </Td>
              </Tr>
              </Tbody>
              </Table>
              
              {transactionId && pendingOpal?.opal !== undefined && (
                  <OpalFile 
                    opal={pendingOpal.opal}
                    mediaHandler={mediaHandler}
                  />
                )}
              </>
            })}
            {(pendingTransaction.length === 0) && <div className="no-opal">No Opals pending</div>}
      </Col>}
    </>
  );
};

export default TransactionSection;
