import React, { useState } from "react";
import { Col, Image } from "react-bootstrap";
import { navigate } from "gatsby";
import clsx from "clsx";
import {
  numberWithCommas,
  getAddress,
  formatPhoneNumber,
} from "../../../utils";
import useComponentVisible from "../../../hooks/useComponentVisible";

const InvestorSection = React.memo(({
  transactionId,
  account,
  user,
  slug,
  ria,
  completedTransaction,
  pendingTransaction,
  parentAccounts,
  cost,
  count,
  monthCost,
}) => {
  const [investSeemore, setInvestSeemore] = useState(false);
  const [riaSeemore, setRiaSeemore] = useState(false);
  const { ref, isComponentVisible } = useComponentVisible(false);

  const backHandler = () => {
    navigate(`/portfolio/`)
  }

  const backListHandler = () => {
    navigate(`/portfolio/${slug}/`)
  }

  const downloadHandler = () => {
    alert('Coming Soon')
  }

  const shareHandler = () => {
    alert('Coming Soon')
  }

  return (
    <Col md={12} className="d-md-block">
      {transactionId ? <>
        <h2>{completedTransaction.length > 0 ? completedTransaction[0].opal_name : pendingTransaction?.[0]?.opal_name}</h2>
        <div className="linkable" onClick={() => backListHandler()}><b>{'< Portfolio - all Opals'}</b></div>
      </>
        :
        <>
          <h2>Portfolio: {(account.type === 'individual' || account.type === 'joint' || account.type === 'sd-ira') ? `${account?.legalFirstName} ${account?.legalLastName}` : account?.entityName}</h2>
          <div className="linkable" onClick={() => backHandler()}><b>{'< Portfolios list'}</b></div>
        </>}
      <div className="investor-detail">
        <div>
          <div className="investor-label">Investor</div>
          <b>{account.type === 'individual' ? `${account?.legalFirstName} ${account?.legalLastName}` : account?.entityName}</b> <br />
          {account.signersName && <>{account.signersName}<br /></>}
          <b><span className='linkable'>{ria ? (account.type === 'individual' || account.type === 'joint' || account.type === 'sd-ira') ? account.investorEmail : account.signersEmail : user.email}</span></b><br />
          <div className={clsx("d-md-block", {
            'd-none': !investSeemore
          })}>
            {getAddress(account, 100)}<br />
            {formatPhoneNumber(account.phoneNo)}
          </div>
          <div
            onClick={() => setInvestSeemore(!investSeemore)}
            className={clsx("linkable d-block d-md-none text-center mt-10", {

            })}><b>{investSeemore ? 'See less' : 'See more'}</b></div>
        </div>
        {ria && <div className="ria-container">
          <div className="investor-label">Registered Investment Advisor</div>
          {/* <b>{parentAccounts[0].entityName}</b> <br /> */}
          <b>{`${user.firstName} ${user.lastName}`}</b> <br />
          <b><span className='linkable'>{user.email}</span></b><br />
          <div className={clsx("d-md-block", {
            'd-none': !riaSeemore
          })}>
            {parentAccounts[0].riaCompany}<br />
            {getAddress(parentAccounts[0], 100)}<br />
            {formatPhoneNumber(parentAccounts[0].phoneNo)}
          </div>
          <div
            onClick={() => setRiaSeemore(!riaSeemore)}
            className={clsx("linkable d-block d-md-none text-center mt-10", {

            })}><b>{riaSeemore ? 'See less' : 'See more'}</b></div>
        </div>}
        <div className="action-container">
          <Image
            onClick={downloadHandler}
            className="pdf-icon"
            src="/assets/images/pdf-download.svg"
          />
          {ria && <Image
            onClick={shareHandler}
            className="share-icon"
            src="/assets/images/share.svg"
          />}
        </div>
      </div>
      <hr />
      <div className="account-detail">

        {new Date().toLocaleDateString('en-us', { year: "numeric", month: "long", day: 'numeric' })}<br /> <div className="seperater">|</div>
        <b>Portfolio balance</b> <span className="linkable mytooltip" ref={ref}><b>&nbsp;(?)&nbsp;</b>
          {isComponentVisible && <span className="tooltiptext">The Portfolio balance includes the Cash Balance plus the current balance of all opals owned and awaiting issuance. </span>}

        </span>
        <br />
        <div className="balance">
          <div>
            ${numberWithCommas(cost)}
          </div>
        </div>
      </div>
    </Col>
  );
});

export default InvestorSection;